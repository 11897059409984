export const gtmData = {
  quote: 'in content get a quote pos=',
  learnMore: 'in content learn more pos=',
  tabbedContentCta: 'tabbed get a quote pos=',
  optionsCardCta: 'options card get a quote pos='
}

export const e164NumCodes = {
  AU: '+61',
  NZ: '+64',
  GB: '+44',
  US: '+1',
  CA: '+1'
}
