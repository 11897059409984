import { defineComponent, h } from 'vue'
import { createApp } from '#root/renderer/app'
import { parseHTML, parseTemplate, defaultTemplatePattern } from './helperTemplate'
import { useInitStore, useContentStore, usePagesStore, useGlobalStore, useVWOStore } from '#root/stores'

export const renderInit = async (pageContext) => {
  const instance = createApp(pageContext)
  const app = instance.app
  const store = instance.store
  const initialStoreState = store.state.value

  const initStore = useInitStore(store)
  await initStore.serverInit({
    app,
    pageContext,
    store
  })

  return { app, store, initialStoreState }
}

// Dynamically creates a vue component for rendering a template based on params
// By default it will parse the template as plain-text unless `allowHTML` is true, in which case
// it will parse the template as HTML
// The template pattern can be customized by setting the `pattern` with a RegExp
export const templateRendererFactory = ({ allowHTML = false, pattern = defaultTemplatePattern } = {}) => {
  const regexp = new RegExp(pattern)

  return defineComponent({
    props: {
      value: {
        type: String,
        required: true
      },
      mapping: {
        type: Object,
        default: () => ({})
      }
    },
    setup(props) {
      const transformer = (node, isTextNode, parsedChildren = []) => {
        if (isTextNode) {
          return parseTemplating(node.wholeText)
        } else {
          // If regular HTML node...
          const attrs = [...node.attributes].reduce((mapped, attr) => {
            return {
              ...mapped,
              [attr.nodeName]: attr.nodeValue
            }
          }, {})

          return [h(node.tagName, attrs, parsedChildren)]
        }
      }

      const parseTemplating = (templating) => {
        const parts = parseTemplate(templating, regexp)

        return parts.map((part) => {
          if (part.isVar) {
            if (part.variable in props.mapping) {
              return props.mapping[part.variable]
            } else {
              throw new Error(`Cannot find mapper "${part.varialble}" in mapping`)
            }
          } else {
            return part.text
          }
        })
      }

      // In either case function return array of VDom nodes
      if (allowHTML) {
        return () => parseHTML(props.value, transformer)
      } else {
        return () => parseTemplating(props.value)
      }
    }
  })
}

export const renderFetchPage = async ({ pageContext, store }) => {
  let campaignKey, assignedVariantName

  const contentStore = useContentStore(store)
  const pagesStore = usePagesStore(store)
  const vwoStore = useVWOStore(store)
  const globalStore = useGlobalStore(store)
  if (globalStore?.trackingEnabled && !globalStore?.isBot) {
    await vwoStore.vwoActivateCampaign(pageContext)
  }

  if (pageContext.urlPathname === '/') {
    const res = vwoStore.activeHomepageCampaign()
    campaignKey = res?.campaignKey
    assignedVariantName = res?.assignedVariantName
    await contentStore.getHomepage({
      campaignKey: campaignKey,
      variantName: assignedVariantName
    })
  } else {
    // server.js remove trailing slash when server rendering so vite does not redirect,
    // so we need to add it back before searching kentico
    let url = pageContext.urlPathname
    if (!url.endsWith('/') && url !== '/') url += '/'
    const res = vwoStore.activePageCampaign(url)
    campaignKey = res?.campaignKey
    assignedVariantName = res?.assignedVariantName
    await contentStore.getPage({
      path: url,
      campaignKey: campaignKey,
      variantName: assignedVariantName
    })
    if (pagesStore.notFound) throw Error('Page not found')
  }

  // DEV ONLY - check for non matching collection items
  // TODO: remove from render to clientside
  // if (import.meta.env.DEV && import.meta.env.SSR) {
  //    const { pageContent } = pagesStore
  //   if (process.env.KENTICO_COLLECTION_BRAND && pageConteng6t?.elements?.page_blocks) {
  //     helperCollections.validator(
  //       pageContent.elements.page_blocks.linkedItems,
  //       process.env.KENTICO_COLLECTION_BRAND
  //     )
  //   }
  // }
  // run brand specific code
  if (pageContext.env.BRAND === 'bsia') {
    const { helperBsiaCtas } = await import('#root/utils/helpers')
    helperBsiaCtas(pageContext.urlPathname, contentStore)
  }
}
