import { helperLogger } from './'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isBetween from 'dayjs/plugin/isBetween'

dayjs.extend(advancedFormat)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)
dayjs.extend(isBetween)

const isDebug = false

/*
const createMap = (country, tzSvcValue, tzIANA, tzLink) => {
  return { country, tzSvcValue, tzIANA, tzLink }
}
const timezoneMap = [
  // https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
  createMap('CA', 'Atlantic Standard Time', 'Canada/Atlantic', 'America/Halifax'),
  createMap('CA', 'Central Standard Time', 'Canada/Central', 'America/Winnipeg'),
  createMap('CA', 'Eastern Standard Time', 'Canada/Eastern', 'America/Toronto'),
  createMap('CA', 'Mountain Standard Time', 'Canada/Mountain', 'America/Edmonton'),
  createMap('CA', 'Newfoundland Standard Time', 'Canada/Newfoundland', 'America/St_Johns'),
  createMap('CA', 'Pacific Standard Time', 'Canada/Pacific', 'America/Vancouver'),
  createMap('CA', '(not supported)', 'Canada/Saskatchewan', 'America/Regina'),
  createMap('CA', '(not supported)', 'Canada/Yukon', 'America/Whitehorse'),
  createMap('UK', 'Greenwich Mean Time', 'Europe/London', 'Europe/London'),
  createMap('AU', 'Australian Eastern Standard Time', 'Australia/Sydney', 'Australia/Sydney'),
  createMap('AU', 'Australian Central Standard Time', 'Australia/Adelaide', 'Australia/Adelaide'),
  createMap('AU', 'Australian Western Standard Time', 'Australia/Perth', 'Australia/Perth'),
  createMap('NZ', 'New Zealand Standard Time', 'Pacific/Auckland', 'Pacific/Auckland')
]
*/

export const formatDate = (inputDate) => {
  const jsDate = new Date(inputDate)
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]
  return monthNames[jsDate.getMonth()] + ' ' + jsDate.getDate() + ', ' + jsDate.getFullYear()
}

export const format = (date, format) => dayjs(date).format(format)

export const getAge = (dob) =>
  // exptected format of dob parameter is:  'yyyy-mm-dd'
  dayjs().diff(dayjs(dob), 'years')

export const validate = (dob) =>
  // validate date exists ie leap year dates
  // check if date is changed, if so invalid
  dayjs(dob, 'YYYY-MM-DD').format('YYYY-MM-DD') === dob

export const getDaysToNextBirthday = (dob) => {
  // exptected format of dob parameter is:  'yyyy-mm-dd'
  if (isDebug) {
    helperLogger.log('getDaysToNextBirthday - dob:', dob)
  }
  const today = dayjs().hour(0).minute(0).second(0).millisecond(0)
  const nextBirthday = getNextBirthday(dob)
  const numDays = nextBirthday.diff(today, 'day')
  if (isDebug) {
    helperLogger.log('getDaysToNextBirthday - days:', numDays)
  }
  return numDays
}

export const getNextBirthday = (rawDob, allowLeapDay = true) => {
  // exptected format of rawDob parameter is:  yyyy-mm-dd
  const today = dayjs().hour(0).minute(0).second(0).millisecond(0)
  let dob = dayjs(rawDob)

  if (!allowLeapDay && dob.month() === 1 && dob.date() === 29) {
    dob = dob.set('month', 2).set('date', 1) // set to March 1st (months are zero-based)
  }

  let nextBirthday = dob.set('year', today.year())

  if (nextBirthday.isBefore(today)) {
    nextBirthday = nextBirthday.add(1, 'year')
  }
  return nextBirthday
}

export const isDateInBetween = (datetime, start, end, unit = 'second') => {
  return dayjs(datetime).isBetween(dayjs(start), dayjs(end), unit)
}

export const isDateAfter = (date1, date2) => dayjs(date1).isAfter(date2, 'minute')

export const isDateBefore = (date1, date2) => dayjs(date1).isBefore(date2, 'minute')

export const getDate = (date) => (!date ? '' : dayjs(date).format('YYYY-MM-DD'))

export const formatToTimeAMPM = (datetime) => dayjs(datetime).format('h:mma')

export const dateObj = (date, tzIANA = '') => {
  if (tzIANA) {
    return dayjs(date).tz(tzIANA)
  } else {
    return dayjs(date)
  }
}

export const utcOffset = (date) => dayjs(date).utc().utcOffset(1, true).format()
