<template>
  <!-- Sub Navigation -->
  <div
    ref="megaNavDropdown"
    :class="[
      'sub-navigation-container',
      activeSubNav === index && subNavState && openDropdown ? 'open' : '',
      activeSubNav === index && subNavState && !openDropdown ? 'animate-in' : 'animate-out'
    ]"
    :content-id="props.content?.system?.id"
    @keydown.esc="emit('toggleSubNavState', index)"
    role="button"
    tabIndex="0"
  >
    <div class="sub-navigation" :class="{ active: activeSubNav === index && subNavState }">
      <div class="title">
        <h5 v-if="contentData.elements.title.value">
          {{ contentData.elements.title.value }}
        </h5>
        <ChevronIcon />
      </div>
      <div v-show="activeSubNav === index" class="content">
        <LinkItem
          :content="contentData.elements.ctas.linkedItems[0]"
          :right-arrow="true"
          :disable-scroll="true"
          :gtm="gtm.subNavProductLink"
          :nofollow="nofollow"
        />
        <LinkItem
          :content="contentData.elements.ctas.linkedItems[1]"
          :right-arrow="true"
          :disable-scroll="true"
          :gtm="gtm.subNavProductQuoteCTA"
          :nofollow="nofollow"
        />
        <HtmlContent :content="contentData.elements.content.value" />
      </div>
      <ul v-show="activeSubNav === index" class="sub-navigation-list">
        <li class="title">{{ contentData.elements.items_title.value }}</li>
        <li v-for="(subLink, j) in contentData.elements.items.linkedItems" :key="j">
          <a
            :href="subLink.elements.url.value"
            :data-gtm="gtm.subNavUsefulLinks"
            :rel="nofollow"
            class="links"
          >
            {{ subLink.elements.title.value }}
          </a>
        </li>
      </ul>
      <ul v-show="activeSubNav === index" class="guides-list">
        <li class="title">{{ contentData.elements.guides_title.value }}</li>
        <li v-for="(guideLink, j) in contentData.elements.guides_items.linkedItems" :key="j">
          <a :href="guideLink.elements.url.value" :data-gtm="gtm.subNavGuideLinks" class="links">
            {{ guideLink.elements.title.value }}
          </a>
        </li>
      </ul>
    </div>

    <button class="menu-accessibliity-close" aria-label="close" @click="toggleSubNavState()">Close</button>
  </div>
  <!-- End sub nav -->
</template>

<script setup>
import { computed } from 'vue'

import LinkItem from '#root/components/buttons/LinkItem.vue'
import HtmlContent from '#root/components/utils/HtmlContent.vue'
import ChevronIcon from '#root/assets/images/icons/chevron-icon.svg'

const props = defineProps({
  content: { type: Object, required: true, default: () => ({}) },
  index: { type: Number, required: true, default: null },
  gtm: { type: Object, required: true, default: () => ({}) },
  nofollow: { type: Boolean, required: false, default: false },
  activeSubNav: { type: Number, required: true, default: null },
  subNavState: { type: Boolean, required: true, default: false },
  openDropdown: { type: Boolean, required: true, default: false }
})

const contentData = computed(() => {
  return props.content
})

const emit = defineEmits(['toggle-sub-nav-state', 'toggleSubNavState'])

const toggleSubNavState = () => {
  emit('toggle-sub-nav-state', props.index)
}
</script>
