import { ref } from 'vue'
import { defineStore } from 'pinia'
import axios from 'axios'
import qs from 'qs'
import { useSetter } from '#root/utils/helpers'
import { useGlobalStore, useUnsubscribeStore, usePagesStore, useArticleStore } from '#root/stores'

// Import novus-server helperContent only when SSR
let helperContent = null

const initServer = async () => {
  if (import.meta.env.SSR) {
    const modules = await import('#server')

    if (modules) {
      helperContent = modules.helpers.content
    }
  }
}

const headers = () => {
  return {
    'Content-Type': 'application/json; charset=utf-8'
  }
}

export const useContentStore = defineStore('content', () => {
  const isPreview = ref(false)
  const [dynamicCta, setDynamicCta] = useSetter('')
  const [isCTAScrollTo, setIsCTAScrollTo] = useSetter(false)

  const init = async () => {
    await initServer()
  }

  const getHeader = async () => {
    try {
      let response
      const pagesStore = usePagesStore()
      const isServer = !!import.meta.env.SSR

      if (isServer && helperContent) {
        response = await helperContent.getHeader()
      } else {
        const option = {
          headers: headers(),
          url: '/content/header',
          method: 'get'
        }
        response = await axios(option)
      }
      pagesStore.setHeader(response)
    } catch (error) {
      //console.log('contentStore -> getHeader - error: ' + error)
    }
  }

  const getFooter = async () => {
    try {
      let response
      const pagesStore = usePagesStore()
      const isServer = !!import.meta.env.SSR

      if (isServer && helperContent) {
        response = await helperContent.getFooter()
      } else {
        const option = {
          headers: headers(),
          url: '/content/footer',
          method: 'get'
        }
        response = (await axios(option)).data
      }
      pagesStore.setFooter(response)
    } catch (error) {
      //console.log('contentStore -> getFooter - error: ' + error)
    }
  }

  const getSidebar = async () => {
    try {
      let response
      const pagesStore = usePagesStore()
      const isServer = !!import.meta.env.SSR

      if (isServer && helperContent) {
        response = await helperContent.getSidebar()
      } else {
        const option = {
          headers: headers(),
          url: '/content/sidebar',
          method: 'get'
        }
        response = await axios(option)
      }
      pagesStore.setSidebar(response)
    } catch (error) {
      console.log('contentStore -> getSidebar - error: ' + error)
    }
  }

  const getOptions = async () => {
    try {
      let response
      const globalStore = useGlobalStore()
      const isServer = !!import.meta.env.SSR

      if (isServer && helperContent) {
        response = await helperContent.getOptions()
      } else {
        const option = {
          headers: headers(),
          url: '/content/options',
          method: 'get'
        }
        response = (await axios(option)).data
      }
      globalStore.setOptions(response)
    } catch (error) {
      console.log('contentStore -> getOptions - error: ' + error)
    }
  }

  const getHomepage = async (params) => {
    const pagesStore = usePagesStore()
    const isServer = !!import.meta.env.SSR

    try {
      const campaignCodename = await getCampaign(params)
      let response
      if (isServer && helperContent) {
        response = await helperContent.getHomepage(params.campaignKey, campaignCodename)
      } else {
        const option = {
          headers: headers(),
          url: '/content/homepage',
          method: 'get',
          params: {
            campaignKey: params.campaignKey,
            campaignCodename
          }
        }
        response = (await axios(option)).data
      }
      pagesStore.setPageContent(response)

      // check if page has a blog listing model, fetch relevant content
      if (pagesStore.pageContent.elements.page_blocks) {
        const blogListings = pagesStore.pageContent.elements.page_blocks.linkedItems.filter((block) => {
          return block.system.type === 'blog_listing_model'
        })
        if (blogListings.length) {
          const articleProduct = blogListings[0].elements.article_product.value.length
            ? blogListings[0].elements.article_product.value[0].codename
            : 'all_guides'
          await getArticleBase({ articleProduct })
        }
      }
      setIsCTAScrollTo(false)
    } catch (error) {
      console.log('contentStore -> getHomepage - error: ' + error)
    }
  }

  const getUnsubscribe = async () => {
    try {
      let response
      const unsubscribeStore = useUnsubscribeStore()
      const isServer = !!import.meta.env.SSR

      if (isServer && helperContent) {
        response = await helperContent.getUnsubscribe()
      } else {
        const option = {
          headers: headers(),
          url: '/content/unsubscribe',
          method: 'get'
        }
        response = (await axios(option)).data
      }
      unsubscribeStore.setUnsubscribe(response)
    } catch (error) {
      console.log('contentStore -> getUnsubscribe - error: ' + error)
    }
  }

  const getPage = async (params) => {
    const pagesStore = usePagesStore()
    const isServer = !!import.meta.env.SSR

    try {
      const [navItem, campaignCodename] = await Promise.all([getNavigationItem(params), getCampaign(params)])
      // Using codename to find content
      const codename = navItem.items[0].system.codename
      const responseElms = navItem.items[0].elements

      let breadcrumbs = []
      if (responseElms.parent.value.length > 0) {
        if (responseElms.parent.value.length > 0) {
          const grandParent = navItem.linkedItems[responseElms.parent.value[0]].elements
          breadcrumbs.push({
            title: grandParent.title.value || '',
            url: grandParent.url.value || ''
          })
        }
        pagesStore.setBreadcrumbs(breadcrumbs)
      } else {
        // Reset the breadcrumbs for pages with no parents
        breadcrumbs = []
        pagesStore.setBreadcrumbs(breadcrumbs)
      }

      let page
      if (isServer && helperContent) {
        page = await helperContent.getPageContent(codename, params.campaignKey, campaignCodename)
      } else {
        const pageOption = {
          headers: headers(),
          url: '/content/page',
          method: 'get',
          params: {
            codename,
            campaignKey: params.campaignKey,
            campaignCodename
          },
          paramsSerializer: (params) => {
            return qs.stringify(params)
          }
        }
        page = (await axios(pageOption)).data
      }
      pagesStore.setPageContent(Object.freeze(page))

      // check if page has a blog listing model, fetch relevant content
      if (pagesStore.pageContent.elements.page_blocks) {
        const blogListings = pagesStore.pageContent.elements.page_blocks.linkedItems.filter((block) => {
          return block.system.type === 'blog_listing_model'
        })
        if (blogListings.length) {
          const articleProduct = blogListings[0].elements.article_product.value.length
            ? blogListings[0].elements.article_product.value[0].codename
            : 'all_guides'
          await getArticleBase({ articleProduct })
        }
      }
      setIsCTAScrollTo(page.elements?.scrollto_get_quote?.value[0] ? true : false)

      if (page.system.type === 'article_template_model') {
        const articleType = page.elements.article_type.value.length
          ? page.elements.article_type.value[0].codename
          : 'blog_article'
        const articlesPerPage = page.elements?.articles_per_page?.value
          ? parseInt(page.elements.articles_per_page.value, 10)
          : 9
        getArticlePage({ articleType, page: 1, articlesPerPage })
      }
    } catch (error) {
      pagesStore.setNotFound(true)
      // console.log('contentStore -> getPage - ' + params.path + ' - error: ' + error)
    }
  }

  const getArticlePage = async (params) => {
    const articleStore = useArticleStore()
    const isServer = !!import.meta.env.SSR

    try {
      let response
      if (isServer && helperContent) {
        response = await helperContent.getArticlePage(params.articleType, params.page, params.articlesPerPage)
      } else {
        const option = {
          headers: headers(),
          url: '/content/article-page',
          method: 'get',
          params: {
            articleType: params.articleType,
            page: params.page,
            articlesPerPage: params.articlesPerPage
          },
          paramsSerializer: (params) => {
            return qs.stringify(params)
          }
        }
        response = (await axios(option)).data
      }
      articleStore.setArticlePage(
        Object.freeze({
          data: response,
          page: params.page,
          articleType: params.articleType
        })
      )
    } catch (error) {
      // console.log('contentStore -> getArticlePage - error: ' + error)
    }
  }

  const getArticleLatest = async (params) => {
    const articleStore = useArticleStore()
    const isServer = !!import.meta.env.SSR

    try {
      let response
      if (isServer && helperContent) {
        response = await helperContent.getArticleLatest(params.articleType)
      } else {
        const option = {
          headers: headers(),
          url: '/content/article-latest',
          method: 'get',
          params: {
            articleType: params.articleType
          },
          paramsSerializer: (params) => {
            return qs.stringify(params)
          }
        }
        response = (await axios(option)).data
      }

      articleStore.setLatest(
        Object.freeze({
          data: response,
          articleType: params.articleType
        })
      )
    } catch (error) {
      console.log('contentStore -> getArticleLatest - error11: ' + error)
    }
  }

  const getArticleTotalCount = async (params) => {
    const articleStore = useArticleStore()
    const isServer = !!import.meta.env.SSR

    try {
      let response
      if (isServer && helperContent) {
        response = await helperContent.getArticleTotalCount(params.articleType, params.articleProduct)
      } else {
        const option = {
          headers: headers(),
          url: '/content/article-total-count',
          method: 'get',
          params: {
            articleType: params.articleType,
            articleProduct: params.articleProduct
          },
          paramsSerializer: (params) => {
            return qs.stringify(params)
          }
        }
        response = (await axios(option)).data
      }
      articleStore.setTotalCount(response.count)
    } catch (error) {
      console.log('contentStore -> getArticleTotalCount - error: ' + error)
    }
  }

  const getArticleBase = async (params) => {
    const articleStore = useArticleStore()
    const isServer = !!import.meta.env.SSR
    try {
      let response
      if (isServer && helperContent) {
        response = await helperContent.getArticleBase({ articleProduct: params.articleProduct })
      } else {
        const option = {
          headers: headers(),
          url: '/content/article-base',
          method: 'get',
          params: {
            articleProduct: params.articleProduct
          }
        }
        response = (await axios(option)).data
      }
      articleStore.setArticleBase(response)
    } catch (error) {
      console.log('contentStore -> getArticleBase - error: ' + error)
    }
  }

  // Return campaign codename
  const getNavigationItem = async (params) => {
    const isServer = !!import.meta.env.SSR

    try {
      let response
      if (isServer && helperContent) {
        response = await helperContent.getNavigationItem(params.path)
      } else {
        const option = {
          headers: headers(),
          url: '/content/navigation-item',
          method: 'get',
          params: {
            path: params.path
          },
          paramsSerializer: (params) => {
            return qs.stringify(params)
          }
        }
        response = (await axios(option)).data
      }
      return response
    } catch (error) {
      console.log('contentStore -> getNavigationItem - error: ' + error)
    }
  }

  // Return campaign codename
  const getCampaigns = async () => {
    const pagesStore = usePagesStore()
    const isServer = !!import.meta.env.SSR

    try {
      let response
      if (isServer && helperContent) {
        response = await helperContent.getCampaignItems()
      } else {
        const option = {
          headers: headers(),
          url: '/content/campaigns',
          method: 'get'
        }
        response = (await axios(option)).data
      }
      pagesStore.setCampaigns(response)
    } catch (error) {
      console.log('contentStore -> getCampaigns - error: ' + error)
    }
  }

  // Return campaign codename
  const getCampaign = async (params) => {
    if (!params.campaignKey || !params.variantName) {
      return null
    }
    const isServer = !!import.meta.env.SSR

    try {
      let response
      if (isServer && helperContent) {
        response = await helperContent.getCampaignItem(params.campaignKey, params.variantName)
      } else {
        const option = {
          headers: headers(),
          url: '/content/campaign-item',
          method: 'get',
          params: {
            campaignKey: params.campaignKey,
            variantName: params.variantName
          }
        }
        response = (await axios(option)).data
      }
      return response?.system?.codename
    } catch (error) {
      console.log('contentStore -> getCampaign - error: ' + error)
    }
  }

  return {
    init,
    isPreview,
    dynamicCta,
    setDynamicCta,
    isCTAScrollTo,
    setIsCTAScrollTo,
    getHeader,
    getFooter,
    getOptions,
    getHomepage,
    getUnsubscribe,
    getPage,
    getArticleLatest,
    getArticlePage,
    getArticleTotalCount,
    getArticleBase,
    getCampaigns,
    getSidebar
  }
})
