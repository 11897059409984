import { createController, serializer } from '#root/utils/helpers/helperApi'

const request = createController('/leadbyte')

export const getLeadByteQueue = (id) =>
  // get queue / lead status
  // https://support.leadbyte.co.uk/hc/en-us/articles/7606513551249-Using-the-QueueID-via-REST-API
  request({
    url: `/apiqueue`,
    method: 'GET',
    params: {
      id
    },
    paramsSerializer: serializer
  })

export const postLeadByte = (lead = {}) =>
  // submit a single new lead
  // https://support.leadbyte.co.uk/hc/en-us/articles/360034762632-Submitting-Leads-via-REST-API
  request({
    url: `/lead`,
    method: 'POST',
    data: lead
  })

export const putLeadByte = (lead = {}) =>
  // update a single lead
  // https://support.leadbyte.co.uk/hc/en-us/articles/360035600631-Update-Leads-via-REST-API
  request({
    url: `/lead`,
    method: 'PUT',
    data: lead
  })
