import { createController } from '#root/utils/helpers/helperApi'

const request = createController('/adsonar')

export const postAdsonarTrack = (data) =>
  request({
    url: '/track',
    method: 'post',
    data
  })
