import axios from 'axios'
import qs from 'qs'

// ---------------------------------- VARIABLES -----------------------------------
const isDebug = false

// ------------------------------------ AXIOS -------------------------------------
axios.defaults.timeout = 30000 // 30 seconds
axios.defaults.withCredentials = true

const standardHeaders = () => ({
  'Access-Control-Allow-Origin': '*',
  'Content-Type': 'application/json; charset=utf-8'
})

const interceptorsRequest = (config) => {
  if (isDebug) {
    const info = {
      url: config.url,
      method: config.method,
      baseURL: config.baseURL,
      headers: config.headers,
      params: config.params,
      data: config.data
    }
    console.warn('HelperApi->request:', info)
  }
  return config
}

const interceptorsResponse = (response) => {
  if (isDebug) {
    const info = {
      status: response.status,
      data: response.data
    }
    console.log('HelperApi->response:', info)
  }
  return response
}

const interceptorsResponseError = (error) => {
  if (isDebug) {
    const info = {
      response: error.response
    }
    console.log('HelperApi->response:', info)
  }

  return Promise.reject(error)
}

// only call in SSR mode
const createHttpsAgent = () => {
  const https = require('https')

  return https.Agent({ rejectUnauthorized: false })
}

// ------------------------------------ EXPORT ------------------------------------
export const getAxiosInstance = () => {
  const axiosOptions = import.meta.env.SSR
    ? {
        httpsAgent: createHttpsAgent(),
        baseURL: process.env.BASE_URL
      }
    : {}

  const axiosInstance = axios.create(axiosOptions)

  axiosInstance.interceptors.request.use(interceptorsRequest)
  axiosInstance.interceptors.response.use(interceptorsResponse, interceptorsResponseError)

  return axiosInstance
}

export const createController = (namespace, pathToAPI = '/api', globalOptions = {}) => {
  return ({ url = '', ...options } = {}) =>
    callAPI({
      url: `${namespace}${url}`,
      pathToAPI,
      ...globalOptions,
      ...options
    })
}

// options.url must be specified
export const callAPI = ({
  method = 'get',
  pathToAPI = '/api',
  data = null,
  headers = standardHeaders(),
  url,
  ...options
}) => {
  const request = getAxiosInstance()

  return request({
    method,
    url: `${pathToAPI}${url}`,
    headers,
    ...(method === 'get' ? { paramsSerializer: (params) => qs.stringify(params) } : {}),
    ...(method !== 'get' ? { data: data !== null ? JSON.stringify(data) : null } : {}),
    ...options
  })
    .then((result) => result.data)
    .catch((err) => {
      if (isDebug) {
        console.log(`${pathToAPI} -> ${url} - error:`, err)
      }

      throw err
    })
}

export const request = (axiosInstance, axiosOptions = {}) =>
  new Promise((resolve, reject) => {
    axiosInstance
      .request(axiosOptions)
      .then((result) => {
        resolve(result.data)
      })
      .catch((err) => {
        // https://www.npmjs.com/package/axios#handling-errors
        reject(err)
      })
  })

export const serializer = (params) => qs.stringify(params)
