<template>
  <div
    ref="megaNavDropdown"
    :class="['sub-navigation-container mobile-nav', subNavState ? 'animate-in' : 'animate-out']"
    class="xl:hidden"
    @keydown.esc="emit('toggleSubNavState', false)"
    role="button"
    tabIndex="0"
  >
    <div
      v-for="(nav, i) in subNavigation"
      :key="i"
      class="sub-navigation"
      :class="{ active: activeSubNav === i && subNavState }"
    >
      <div
        class="title"
        role="button"
        tabindex="0"
        @click="setOpenDropdown(i)"
        @keyup.enter.prevent="setOpenDropdown(i)"
      >
        <h5 v-if="nav.elements.title.value">{{ nav.elements.title.value }}</h5>
        <ChevronIcon />
      </div>
      <div v-show="activeSubNav === i" class="content">
        <LinkItem
          :content="nav.elements.ctas.linkedItems[0]"
          :right-arrow="true"
          :disable-scroll="true"
          :gtm="gtm.subNavProductLink"
          :nofollow="nofollow"
        />
        <LinkItem
          :content="nav.elements.ctas.linkedItems[1]"
          :right-arrow="true"
          :disable-scroll="true"
          :gtm="gtm.subNavProductQuoteCTA"
          :nofollow="nofollow"
        />
        <HtmlContent :content="nav.elements.content.value" />
      </div>
      <ul v-show="activeSubNav === i">
        <li class="title">{{ nav.elements.items_title.value }}</li>
        <li v-for="(link, j) in nav.elements.items.linkedItems" :key="j">
          <a :href="link.elements.url.value" :gtm="gtm.subNavUsefulLinks" :rel="nofollow" class="links">
            {{ link.elements.title.value }}
          </a>
        </li>
      </ul>
      <ul v-show="activeSubNav === i" class="guides-list">
        <li class="title">{{ nav.elements.guides_title.value }}</li>
        <li v-for="(link, j) in nav.elements.guides_items.linkedItems" :key="j">
          <a :href="link.elements.url.value" :gtm="gtm.subNavGuideLinks" class="links">
            {{ link.elements.title.value }}
          </a>
        </li>
      </ul>
    </div>
    <div v-if="navigationLinks" class="sub-links">
      <ul>
        <li v-for="(link, i) in navigationLinks" :key="i">
          <a :href="link.elements.url.value" :data-gtm="gtm.headerNav" :rel="nofollow">
            {{ link.elements.title.value }}
          </a>
        </li>
      </ul>
    </div>
    <button class="menu-accessibliity-close" aria-label="close" @click="toggleSubNavState()">Close</button>
  </div>
</template>

<script setup>
import { ref, nextTick } from 'vue'
import LinkItem from '#root/components/buttons/LinkItem.vue'
import HtmlContent from '#root/components/utils/HtmlContent.vue'
import ChevronIcon from '#root/assets/images/icons/chevron-icon.svg'

const props = defineProps({
  subNavigation: { type: Object, required: true, default: () => ({}) },
  navigationLinks: { type: Object, required: true, default: () => ({}) },
  gtm: { type: Object, required: false, default: () => ({}) },
  nofollow: { type: Boolean, required: false, default: false },
  activeSubNav: { type: Number, required: true, default: null },
  subNavState: { type: Boolean, required: true, default: false }
})

const emit = defineEmits(['toggle-sub-nav-state', 'set-open-dropdown', 'toggleSubNavState'])

const toggleSubNavState = (i) => {
  console.log('toggleSubNavState', i)
  emit('toggle-sub-nav-state', i)
}

const setOpenDropdown = (i) => {
  scrollToTop()
  emit('set-open-dropdown', i)
}

const megaNavDropdown = ref(null)
const scrollToTop = () => {
  nextTick(() => {
    megaNavDropdown.value.scrollTop = 64
  })
}
</script>
