import { ref, computed, watch } from 'vue'
import { defineStore } from 'pinia'

import { callCenterName, formatPhoneNumber } from '#config'
import { useSetter, helperApi } from '#root/utils/helpers'
import { helperDateTime } from '#root/utils/helpers'
import { getCustomerCountryTimezone, getCallbackHours, postTransactions } from '#root/api'

const cookieMaxAge = 1000 * 60 * 60 * 24 * 365 // using express, maxAge in milliseconds
const cookieAge = 1000 * 60 * 30 // 30 minutes

const isDebug = false

export const useGlobalStore = defineStore('global', () => {
  const [options, setOptions] = useSetter(null)
  const [config, setConfig] = useSetter({})
  const [locale, setLocale] = useSetter(null)
  const [trackingEnabled, setTrackingEnabled] = useSetter(false)
  const [visitorIP, setVisitorIP] = useSetter(null)
  const [windowSize, setWindowSize] = useSetter(null)
  const [callBackHours, setCallbackHours] = useSetter(null)
  const [isBot, setIsBot] = useSetter(false)
  const visitorId = ref(null)
  const transactionId = ref(null)
  const [queryParams, setQueryParams] = useSetter({})
  const [lastPage, setLastPage] = useSetter('/')
  const visitorLocation = ref('')
  const httpReferrer = ref('')
  const modal = ref({
    genericModal: false,
    formModal: false,
    warningModal: false,
    content: null
  })
  let popouts = []
  const callCenterOpen = ref(false)

  const dynamicPhoneNumber = computed(() => {
    const fallbackPhone = options.value?.elements?.fallback_phone_number?.value || false
    return formatPhoneNumber(config.value.telephoneNumber, fallbackPhone)
  })

  const checkCallCenterOpen = () => {
    if (!callBackHours.value) return
    let callCenterInterval = null
    const check = () => {
      const callBackHoursValue = callBackHours.value
      const dates = callBackHoursValue.dates || []
      const now = helperDateTime.dateObj(
        new Date(),
        visitorLocation.value.timeZone,
        visitorLocation.value.countryCode
      )
      for (const date of dates) {
        if (helperDateTime.isDateInBetween(now, date.startDateTime, date.endDateTime)) {
          callCenterOpen.value = true
        }
      }
    }
    check()
    callCenterInterval = setInterval(() => {
      check()
    }, 60000)
  }

  const updateVisitorLocation = async () => {
    if (import.meta.env.SSR) {
      const { api } = await import('#server')

      try {
        visitorLocation.value = await api.apiCustomers.getCustomerCountryTimezone(visitorIP.value)
      } catch (err) {
        visitorLocation.value =
          err.code === 'IP_ADDRESS_RESERVED'
            ? { countryCode: 'IE', timeZone: 'Europe/Dublin' }
            : { countryCode: '', timeZone: '' }
      }
    } else {
      try {
        visitorLocation.value = await getCustomerCountryTimezone({ ip: visitorIP.value })
      } catch (err) {
        visitorLocation.value =
          err.response.status === 400
            ? { countryCode: 'IE', timeZone: 'Europe/Dublin' }
            : { countryCode: '', timeZone: '' }
      }
    }
  }

  // https://developers.facebook.com/docs/marketing-api/conversions-api/parameters/fbp-and-fbc
  const calculateFacebookValues = ({ cookies, fbclid }) => {
    const version = 'fb'
    const subdomainIndex = 1
    const creationTime = Date.now()
    const data = {}
    data.fbc = cookies._fbc || ''
    data.fbp = cookies._fbp || ''

    if (!data.fbc && fbclid) {
      data.fbc = `${version}.${subdomainIndex}.${creationTime}.${fbclid}`
    }

    Object.keys(data).forEach((key) => data[key] === '' && delete data[key])

    return data
  }

  const postTransactionSSR = async ({ request: req, response: res }) => {
    if (isBot.value) {
      return
    }

    if (req) {
      const { api, helpers } = await import('#server')
      // TODO: We may want to consolidate these setup logic into a helper in novus-server because we are doing the same setup in the post transaction route
      // we are setting res.locals based on query string in Novus-Server mwareApp and it looks like cookie isn't available to read yet on first page load
      const source = req.cookies.rc || req.res.locals?.cookies?.rc
      // replace all occurrences of // with / _after_ the first one (which will be https://)
      const url = `${process.env.BASE_URL}${req.url}`.replace(
        /(.*?\/\/)(.*)/g,
        (_, g1, g2) => g1 + g2.replace(/\/\//g, '/')
      )
      const currentLandingUrl = new URL(url)
      const landingUrlParams = {
        ...[...currentLandingUrl.searchParams.keys()].reduce(
          (all, key) => ({
            ...all,
            [key]: currentLandingUrl.searchParams.get(key)
          }),
          {}
        ),
        ...calculateFacebookValues({
          cookies: req.cookies,
          fbclid: req.query?.fbclid ?? ''
        })
      }

      const paramString = helperApi.serializer(landingUrlParams)
      const landingUrl = `${currentLandingUrl.origin}${currentLandingUrl.pathname}${
        paramString.length > 0 ? '?' : ''
      }${paramString}`

      httpReferrer.value = import.meta.env.SSR ? req?.headers.referer ?? '' : document?.referrer ?? ''
      const transactionData = {
        brand: config.value.brandId,
        source,
        landingUrl, // Service logic requires that url includes the protocol
        visitorId: req.cookies?.visitorId,
        httpReferrer: httpReferrer.value, // (sic) https://en.wikipedia.org/wiki/List_of_HTTP_header_fields#Field_names
        ipAddress: visitorIP.value,
        userAgent: req.headers['user-agent'],
        cjEvent: req.cookies.clickref
      }

      const headers = helpers.routes.getHeaderAsObject(req)
      const result = await api.apiLeads.createTransaction(transactionData, headers)
      const secure = !isDebug

      res.cookie('visitorId', result.visitorId, {
        secure,
        maxAge: cookieMaxAge,
        path: '/',
        httpOnly: true,
        sameSite: 'strict'
      })
      res.cookie('TransactionId', result.transactionId, {
        secure,
        maxAge: cookieAge,
        path: '/',
        sameSite: 'strict'
      })

      if (result.visitorId) {
        visitorId.value = result.visitorId
      }
      if (result.transactionId) {
        transactionId.value = result.transactionId
      }
      setConfig(result)
    }
  }

  const postTransactionClient = async (url) => {
    if (isBot.value) {
      return
    }

    try {
      const payload = {
        brand: config.value.brandId,
        landingUrl: url.toString(),
        httpReferrer: document.referrer,
        sameTransaction: true,
        source: queryParams.value.rc || config.value.refCode || '',
        visitorId: visitorId.value
      }

      const res = await postTransactions({ data: payload })

      if (res.visitorId) {
        visitorId.value = res.visitorId
      }
      if (res.transactionId) {
        transactionId.value = res.transactionId
      }
      setConfig(res)
    } catch {
      // post transaction failed
    }
  }

  // Only called server side
  const getTransaction = async (pageContext) => {
    if (pageContext.request) {
      setQueryParams(pageContext.request.query)
    }

    if (isDebug) {
      console.log('getTransaction - data:', JSON.stringify(data))
    }

    const res = await postTransactionSSR(pageContext ?? null)

    if (isDebug) {
      console.log('getTransaction - response', JSON.stringify(res))
    }
  }

  // This should only be called client side. Changes required if we need to call server side
  const setTransactionVwo = (vwoCampaignData, landingPage) => {
    const url = new URL(
      import.meta.env.SSR
        ? `${process.env.BASE_URL}${landingPage}`
        : `${window.location.origin}${landingPage}`
    )

    if (typeof vwoCampaignData === 'object') {
      const base64Params = new URLSearchParams()
      for (const [key, value] of Object.entries(vwoCampaignData)) {
        if (Array.isArray(value)) {
          value.forEach((obj) => {
            for (const [objKey, objValue] of Object.entries(obj)) {
              base64Params.append(`campaign[${obj.campaignKey}][${objKey}]`, objValue)
            }
          })
        } else {
          base64Params.append(key, value)
        }
      }
      url.searchParams.append('vwos2s', btoa(base64Params.toString()))
    } else {
      url.searchParams.append('vwo', vwoCampaignData || false)
    }

    return postTransactionClient(url)
  }

  // We do not need to call refresh transaction until we work on BOJ. This is only for ISTP purposes
  /* const refreshTransaction = (urlParsed, tranxId) => {
    if (urlParsed?.search) setQueryParams(urlParsed.search)

    return postRefreshTransactions(tranxId)
      .then((res) => {
        if (isDebug) {
          console.log('postRefreshTransactions - response', JSON.stringify(res))
        }

        if (res.visitorId) {
          visitorId.value = res.visitorId
        }
        if (res.transactionId) {
          transactionId.value = res.transactionId
        }
        setConfig(res)
        return res
      })
      .catch((error) => {
        console.log(error)
      })
  } */

  const toggleModal = (modalName, content) => {
    modal.value[modalName] = !modal.value[modalName]
    modal.value.content = content
  }

  const togglePopout = (popoutName) => {
    if (popouts.indexOf(popoutName) !== -1) {
      popouts = popouts.filter((e) => e !== popoutName)
      return false
    } else {
      popouts.push(popoutName)
      return true
    }
  }

  const fetchCallbackHours = async (req = null) => {
    const date = new Date()
    const yesterday = new Date(date.setDate(date.getDate() - 1))
    const params = {
      interval: 15,
      startDate: yesterday.toISOString(),
      daysToSchedule: 5,
      callCenter: callCenterName
    }
    if (import.meta.env.SSR) {
      const { api, helpers } = await import('#server')
      const headers = helpers.routes.getHeaderAsObject(req)

      const callbackHours = await api.apiWrapup.callbackHours(params, headers)
      setCallbackHours(callbackHours)
    } else {
      const callbackHours = await getCallbackHours(params)
      setCallbackHours(callbackHours)
    }
  }

  return {
    // states
    options,
    config,
    visitorId,
    visitorIP,
    transactionId,
    visitorLocation,
    queryParams,
    modal,
    locale,
    trackingEnabled,
    isBot,
    callBackHours,
    windowSize,
    lastPage,

    // computed
    dynamicPhoneNumber,
    callCenterOpen,

    // actions
    getTransaction,
    // refreshTransaction,
    toggleModal,
    togglePopout,
    updateVisitorLocation,
    fetchCallbackHours,
    checkCallCenterOpen,

    // setters
    setOptions,
    setConfig,
    setLocale,
    setTrackingEnabled,
    setTransactionVwo,
    setWindowSize,
    setVisitorIP,
    setCallbackHours,
    setIsBot,
    setLastPage
  }
})
