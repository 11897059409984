export const optimise = (image, lossless = 0, options) => {
  if (!image) return null
  if (typeof image === 'string' && image.includes('http')) {
    return `${image}?fm=webp&lossless=${lossless}`
  } else {
    const renditionQuery = image.renditions?.default?.query || ''
    if (options) {
      options = Object.keys(options)
        .map((key) => `${key}=${options[key] || null}`)
        .join('&')
      return renditionQuery
        ? `${image.url}?fm=webp&lossless=${lossless}&${renditionQuery}&${options}`
        : `${image.url}?fm=webp&lossless=${lossless}&${options}`
    } else {
      return renditionQuery
        ? `${image.url}?fm=webp&lossless=${lossless}&${renditionQuery}`
        : `${image.url}?fm=webp&lossless=${lossless}`
    }
  }
}
