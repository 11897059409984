import { createController } from '#root/utils/helpers/helperApi'

const request = createController('/customers')

export const getValidateEmailDetailed = ({ email }) =>
  request({
    url: '/validate-email-detailed',
    method: 'get',
    params: {
      email
    }
  })

export const getValidateEmail = ({ email }) =>
  request({
    url: '/validate-email',
    method: 'get',
    params: {
      email
    }
  })

export const getCustomerAddressSearch = ({ postCode, container }) =>
  request({
    url: '/address/search',
    method: 'get',
    params: {
      searchTerm: postCode,
      container
    }
  })

export const getCustomerAddressSearchRetrieve = ({ addressId }) =>
  request({
    url: '/address/search/retrieve',
    method: 'get',
    params: {
      addressId
    }
  })

export const getPaymentDays = ({ days }) =>
  request({
    url: '/payment-days',
    method: 'get',
    params: {
      days
    }
  })

export const postCustomer = ({ data }) =>
  request({
    method: 'post',
    data
  })

export const postCustomerAddress = ({ data }) =>
  request({
    url: '/addresses',
    method: 'post',
    data
  })

export const postCustomerPayment = ({ data }) =>
  request({
    url: '/payments',
    method: 'post',
    data
  })

export const postCustomerAgreement = ({ data }) =>
  request({
    url: '/agreements',
    method: 'post',
    data
  })

export const postMarketingPreference = ({ data }) =>
  request({
    url: '/marketing-preferences',
    method: 'post',
    data
  })

export const getCustomerCountryTimezone = () =>
  request({
    method: 'get',
    url: '/country-tz'
  })

export const putUnsubscribeRequest = ({ data }) =>
  request({
    method: 'put',
    url: '/unsubscribe',
    data
  })
