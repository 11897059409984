import * as date from './Date'

// ------------------------------------ CLASSES ------------------------------------
export class Customer {
  constructor(
    customerId = null,
    firstName = null,
    lastName = null,
    dob = null,
    email = null,
    postCode = null,
    phone = null,
    province = null,
    provinceId = null,
    ageCheck = null,
    coverGroup = null,
    gender = null,
    coverType = null,
    coverAmount = null,
    smoker = null,
    leadbyteQueueId = null
  ) {
    this.customerId = customerId
    this.firstName = firstName
    this.lastName = lastName
    this.dob = new date.Parts(dob?.year ?? null, dob?.month ?? null, dob?.day ?? null)
    this.ageCheck = ageCheck
    this.email = email
    this.postCode = postCode
    this.phone = phone
    this.province = province
    this.provinceId = provinceId
    this.coverGroup = coverGroup
    this.gender = gender
    this.coverType = coverType
    this.coverAmount = coverAmount
    this.smoker = smoker
    this.leadbyteQueueId = leadbyteQueueId
  }

  clone() {
    const cloned = new this.constructor(
      this.customerId,
      this.firstName,
      this.lastName,
      this.dob.clone(),
      this.email,
      this.postCode,
      this.phone,
      this.province,
      this.provinceId,
      this.ageCheck,
      this.coverGroup,
      this.gender,
      this.coverType,
      this.coverAmount,
      this.smoker,
      this.leadbyteQueueId
    )
    return cloned
  }

  mapToServices() {
    return {
      firstName: this.firstName,
      lastName: this.lastName,
      dateOfBirth: this.dob?.toPostString() ?? null,
      ageCheck: this.ageCheck,
      phoneNumber: this.phone,
      email: this.email,
      postCode: this.postCode,
      province: this.province,
      provinceId: this.provinceId,
      coverGroup: this.coverGroup,
      gender: this.gender,
      coverType: this.coverType,
      coverAmount: this.coverAmount,
      smoker: this.smoker
    }
  }
  mapToRequest() {
    return {
      firstName: this.firstName,
      lastName: this.lastName,
      phoneNumber: this.phone,
      email: this.email,
      postCode: this.postCode,
      province: this.province,
      provinceId: this.provinceId,
      coverGroup: this.coverGroup,
      gender: this.gender,
      coverType: this.coverType,
      coverAmount: this.coverAmount,
      smoker: this.smoker
    }
  }

  mapToTracking() {
    return {
      firstName: this.firstName,
      lastName: this.lastName,
      phoneH: this.phone,
      phoneM: this.phone,
      dob: '',
      email: this.email,
      coverGroup: this.coverGroup,
      gender: this.gender,
      coverType: this.coverType,
      coverAmount: this.coverAmount,
      smoker: this.smoker
    }
  }
}
