<!--
  * Component: Footer Social
  * Description: Social links for footer component
  * Props:
      facebookUrl, twitterUrl
  * Usage:
      <footer-social />
-->

<template>
  <div>
    <div v-if="props.image" class="footer-image">
      <img
        :src="helperImage.optimise(props.image)"
        :height="props.image.height"
        :width="props.image.width"
        :alt="props.image?.description || 'Footer Image'"
        fetchpriority="low"
        decoding="async"
        loading="lazy"
      />
    </div>
    <div v-if="social" class="footer-contact-social mt-4 lg:mt-0">
      <a
        v-if="social.facebook"
        :href="social.facebook"
        class="social-icon"
        :data-gtm="gtm.socialLinks + ' facebook'"
        :rel="'noopener noreferrer ' + nofollow"
        aria-label="Facebook"
        target="_blank"
      >
        <SvgIcon name="facebook-icon" class="info" />
      </a>
      <a
        v-if="social.twitter"
        :href="social.twitter"
        class="social-icon"
        :data-gtm="gtm.socialLinks + ' twitter'"
        :rel="'noopener noreferrer ' + nofollow"
        aria-label="Twitter"
        target="_blank"
      >
        <SvgIcon name="twitter-icon" class="info" />
      </a>
      <a
        v-if="social.instagram"
        :href="social.instagram"
        class="social-icon"
        :data-gtm="gtm.socialLinks + ' instagram'"
        :rel="'noopener noreferrer ' + nofollow"
        aria-label="Instagram"
        target="_blank"
      >
        <SvgIcon name="instagram-icon" class="info" />
      </a>
      <a
        v-if="social.youtube"
        :href="social.youtube"
        class="social-icon"
        :data-gtm="gtm.socialLinks + ' youtube'"
        :rel="'noopener noreferrer ' + nofollow"
        aria-label="Youtube"
        target="_blank"
      >
        <SvgIcon name="youtube-icon" class="info" />
      </a>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'

import { helperImage } from '#root/utils/helpers'
import { useGetContentOptions } from '#root/components/composables/getContent.js'
import SvgIcon from '#root/components/utils/SvgIcon.vue'

const props = defineProps({
  social: { type: Object, required: true, default: () => {} },
  nofollow: { type: String, required: false, default: '' },
  image: { type: Object, required: false, default: null }
})

const gtm = computed(() => {
  return {
    socialLinks: useGetContentOptions('social_links.value', '')
  }
})
</script>

<style lang="scss">
.footer-contact-social {
  @include flex-parent(row, wrap, center, center);
  flex: 1;
  color: $footer-icons;

  @include media-query('tablet') {
    justify-content: end;
  }
}

.footer-image {
  @include flex-parent(row, nowrap, center, center);
  margin-top: $spacing-4;
  img {
    max-width: 185px;
  }

  @include media-query('tablet') {
    @include flex-parent(row, nowrap, flex-end, center);
    margin-top: 0;
    margin-bottom: $spacing-4;
    margin-right: $spacing-4;
  }
}

.social-icon {
  display: block;
  margin: 0 $spacing-2;
}
</style>
