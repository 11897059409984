export const getCoords = (elem) => {
  const box = elem.getBoundingClientRect()

  const body = document.body
  const docEl = document.documentElement

  const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop
  const scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft

  const clientTop = docEl.clientTop || body.clientTop || 0
  const clientLeft = docEl.clientLeft || body.clientLeft || 0

  const top = box.top + scrollTop - clientTop
  const left = box.left + scrollLeft - clientLeft

  return { top: Math.round(top), left: Math.round(left) }
}

export const scrollToElement = (selector, extraSpace = 0) => {
  const selectedNode = document.querySelectorAll(selector)
  let headerHeight = 0
  let noticeHeight = 0

  if (selectedNode.length) {
    const header = document.getElementsByClassName('global-header')
    if (header.length && header[0].offsetHeight) {
      headerHeight = header[0].offsetHeight
    }

    const notice = document.getElementsByClassName('notice')
    if (notice.length && notice[0].offsetHeight) {
      noticeHeight = notice[0].offsetHeight
    }

    const { top } = getCoords(selectedNode[0])
    if (top) {
      const newPosition = top - extraSpace // - headerHeight - noticeHeight

      if (!!window.MSInputMethodContext && !!document.documentMode) {
        // IE 11
        window.scrollTo(0, newPosition)
      } else {
        window.scrollTo({ top: newPosition + 1, behavior: 'smooth' })
      }
    }
  }
}

export const scrollToHash = (pageContext) => {
  if (pageContext.urlParsed.hashOriginal) {
    const hash = pageContext.urlParsed.hashOriginal
    setTimeout(() => {
      if (hash) {
        scrollToElement(hash, 240)
      }
    }, 250)
  }
}
