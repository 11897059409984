<template>
  <slot v-if="props.overRide || mountClientOnly" />
</template>

<script setup>
import { ref, onMounted } from 'vue'

const props = defineProps({
  overRide: {
    type: Boolean,
    default: false
  }
})
const mountClientOnly = ref(false)
onMounted(() => {
  mountClientOnly.value = true
})
</script>
