import { ref } from 'vue'

export const useSetter = (initialValue) => {
  const atom = ref(initialValue)
  const mutator = (newValue) => {
    atom.value = newValue
  }

  return [atom, mutator]
}
