export const qbAction = {
  // actions for store/tracking.js -> trackQuoteAndBuyInteraction()
  DisplayClientDetails: 'DisplayClientDetails',
  DisplayQuoteOptions: 'DisplayQuoteOptions',
  // SwitchToSelectByRecommendation: 'SwitchToSelectByRecommendation', // not needed?
  // SwitchToSelectByDropdown: 'SwitchToSelectByDropdown', // not needed?
  // SwipeCoverChanged: 'SwipeCoverChanged', // not in scope
  DisplayReviewPurchase: 'DisplayReviewPurchase',
  // DisplayReviewCoverChoice: 'DisplayReviewCoverChoice', // not needed?
  // DisplayPurchaseDetails: 'DisplayPurchaseDetails', // not needed?
  DisplayConfirmation: 'DisplayConfirmation'
}

export const qbActionForExternal = {
  clientDetails: 'client-details',
  quoteOptions: 'quote-options',
  reviewPurchase: 'review-purchase',
  // reviewCover: 'review-cover', // not needed?
  // purchaseDetails: 'purchase-details', // not needed?
  summary: 'summary'
}

export const lead = {
  CallMeNow: 'CallMeNow',
  QuoteRequest: 'QuoteRequest',
  InfoPackRequest: 'InfoPackRequest',
  // QuickCallback: 'QuickCallback', // not needed?
  OnlineQuote: 'OnlineQuote', // Online Purchasing
  WillKit: 'WillKit',
  LeadByte: 'QuoteRequest',
  Funnel: 'Funnel'
}

export const callback = {
  Abandoned: 'Abandoned',
  Opened: 'Opened',
  Submitted: 'Submitted'
}
