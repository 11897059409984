const partBase = {
  isVar: false,
  variable: null,
  text: null
}

// default RegExp used for matching patterns
export const defaultTemplatePattern = /\[\[([a-zA-Z_]+)\]\]/

// Parses a string which may contain templates into alternating template/literal string parts array
// Template pattern can be customized in second argument with RegExp
export const parseTemplate = (template, pattern = defaultTemplatePattern) => {
  const parts = []
  let offset = 0

  while (offset < template.length) {
    const match = pattern.exec(template.slice(offset))

    if (match) {
      const { [0]: matchText, [1]: variable, index } = match
      const text = template.slice(offset, offset + index)

      parts.push({ ...partBase, text })
      parts.push({ ...partBase, isVar: true, variable })

      offset += index + matchText.length
    } else {
      parts.push({ ...partBase, text: template.slice(offset) })
      offset = template.length
    }
  }

  return parts.filter((part) => part.isVar || part.text.length > 0)
}

// Parses HTML and applies a provided transformer recursively to each node.
// Created for converting HTML string to Vue VDom representation
export const parseHTML = (template, transformer) => {
  const transformNode = (node) => {
    const childNodes = []
    const isTextNode = node.nodeType === 3

    for (const childNode of node.childNodes) {
      childNodes.push(...transformNode(childNode))
    }

    return transformer(node, isTextNode, childNodes)
  }

  const parser = new DOMParser()
  const { body } = parser.parseFromString(template, 'text/html')
  const parsedNodes = []

  for (const childNode of body.childNodes) {
    parsedNodes.push(...transformNode(childNode))
  }

  return parsedNodes
}
