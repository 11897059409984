import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(customParseFormat)

const padZero = (value) => {
  value = '0' + value.toString()
  return value.substr(value.length - 2, value.length)
}

// ------------------------------------ CLASSES ------------------------------------
export class Model {
  static unhash(string) {
    const [year, month, day] = string.split('-')

    return new this(year, month, day)
  }

  constructor(year = '', month = '', day = '') {
    this.year = year
    this.month = month
    this.day = day
  }

  hash() {
    return `${this.year}-${this.month}-${this.day}`
  }

  toNumeric() {
    return new Parts(parseInt(this.year), parseInt(this.month), parseInt(this.day))
  }
}

export class Parts {
  constructor(year = null, month = null, day = null) {
    this.month = month
    this.day = day
    this.year = year
  }

  clone() {
    const cloned = new Parts()
    cloned.day = this.day
    cloned.month = this.month
    cloned.year = this.year
    return cloned
  }

  getPaddedString() {
    return `${this.year}-${padZero(this.month)}-${padZero(this.day)}`
  }

  isEqual(date) {
    // date is dayjs obj
    return date.month() + 1 === this.month && date.date() === this.day && date.year() === this.year
  }

  isValid() {
    return dayjs(this.getPaddedString(), 'YYYY-MM-DD', true).isValid()
  }

  toString() {
    if (!this.month || !this.day || !this.year) {
      return ''
    }
    return dayjs(this.getPaddedString(), 'YYYY-M-D').format('ddd D MMM YYYY')
  }

  toPostString() {
    if (!this.month || !this.day || !this.year) {
      return ''
    }
    return dayjs(this.getPaddedString(), 'YYYY-MM-DD', true).format('YYYY-MM-DD')
  }
}
