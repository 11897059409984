<template>
  <slot />
</template>

<script setup>
import { onBeforeMount, watch, onMounted } from 'vue'
import { storeToRefs } from 'pinia'
import { helperScroll } from '#root/utils/helpers'
import { usePageContext } from '#root/renderer/usePageContext'
import { useGlobalStore, useTrackingStore, useFormStore, useVWOStore } from '#root/stores'
import lodashDebounce from 'lodash/debounce'
import { useGetContentOptions } from '#root/components/composables/getContent'
import sha256 from 'crypto-js/sha256'
import encHex from 'crypto-js/enc-hex'

const globalStore = useGlobalStore()
const trackingStore = useTrackingStore()
const vwoStore = useVWOStore()
const formStore = useFormStore()
const pageContext = usePageContext()
const { vwoActivatedCampaigns } = storeToRefs(vwoStore)

onBeforeMount(() => {
  globalStore.checkCallCenterOpen()
  globalStore.setWindowSize(windowSize())
  window.addEventListener(
    'resize',
    lodashDebounce(() => {
      globalStore.setWindowSize(windowSize())
    }, 100)
  )
})

watch(
  () => trackingStore.onetrustAccepted,
  (newVal, oldVal) => {
    if (newVal !== oldVal) {
      if (vwoActivatedCampaigns.value.length) {
        vwoActivatedCampaigns.value.forEach((c) => {
          vwoStore.vwoGTMPush(c.campaign_key.value, pageContext)
        })
      }
    }
  }
)

onMounted(() => {
  if (!globalStore.isBot) {
    exitWarningListener()
    window.CryptoJS = { SHA256: sha256, enc: { Hex: encHex } }
    globalStore.setLastPage(pageContext.urlOriginal)
    vwoStore.vwoTransaction(pageContext)
    vwoStore.vwoPush(pageContext)
    trackingStore.mapDataLayerOneTrustLoadOrder(pageContext)
    setTimeout(() => vwoStore.vwoRegisterEventListeners(pageContext), 2000) // some elements are not ready yet
  }
})

watch(
  () => pageContext.urlParsed.hashOriginal,
  (newVal, oldVal) => {
    helperScroll.scrollToHash(pageContext)
  }
)

// watching just the url changes instead of the whole pageContext object
watch(
  () => pageContext.urlOriginal,
  async (newVal, oldVal) => {
    if (newVal !== oldVal) {
      globalStore.setLastPage(oldVal)
      vwoStore.vwoTrackRoute(newVal, pageContext)
      trackingStore.trackRouteChange(newVal)
      setTimeout(() => vwoStore.vwoRegisterEventListeners(pageContext), 2000) // some elements are not ready yet
      formStore.setActiveStep({ step: '', index: 0 })
    }
  }
)

const exitWarningListener = () => {
  window.addEventListener('exitWarning', (e) => {
    globalStore.toggleModal('warningModal', {
      title: useGetContentOptions('warning_modal_title.value', null),
      content: useGetContentOptions('warning_modal_content.value', null),
      cta1: useGetContentOptions('warning_modal_leave_button.linkedItems[0]', null),
      cta2: useGetContentOptions('warning_modal_stay_button.linkedItems[0]', null)
    })
  })
}

const windowSize = () => {
  return {
    width: window.innerWidth,
    height: window.innerHeight,
    isMobile: window.innerWidth < 560 ? true : false,
    isPhablet: window.innerWidth >= 560 && window.innerWidth < 768 ? true : false,
    isTablet: window.innerWidth >= 768 && window.innerWidth < 1024 ? true : false,
    isDesktop: window.innerWidth >= 1024 ? true : false,
    isDesktopLg: window.innerWidth >= 1280 ? true : false
  }
}
</script>

// layout needs defined empty class to pull in all styles
<style lang="scss">
#app {
}

@include media-query('desktop') {
  [href^='tel:'] {
    pointer-events: none;
  }
}
</style>
