<!--
  * Component Name: Cookie Bar
  * Description: Display message to accept cookies and alert if the cookies are off in the browser
  * Props:
      isAlert, notice, alert
  * Components:
      none
  * Usage:
    <cookie-bar
      :notice="value"
    />
    <cookie-bar
      :alert="value"
    />
-->

<template>
  <div v-if="!cookieSet && !cookieContent.hide" class="">
    <div
      v-if="cookieChecks()"
      class="cookie-container"
      :class="[{ hide: cookieSet }]"
      :content-id="props.content?.system?.id"
    >
      <div class="grid-container mx-auto px-4 xxl:px-6">
        <div class="cookie py-2">
          <div class="cookie-notice">
            <InfoIcon role="img" aria-label="info" class="info-icon" />
            <html-content class="cookie-content" :content="cookieContent.cookie" :gtm="gtm.cookieLink" />
          </div>
          <a
            class="cookie-close"
            :data-gtm="gtm.cookieBtn"
            aria-label="Accept Cookies"
            @click="handleClick"
            @keyup.enter.prevent="handleClick"
            role="button"
            tabIndex="0"
          >
            OK
          </a>
        </div>
      </div>
    </div>
    <div v-if="!cookieChecks()" class="cookie-container alert">
      <div class="cookie-notice">
        <InfoIcon role="img" aria-label="info" class="info-icon" />
        <html-content class="cookie-content" :content="cookieContent.alert" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, computed, ref, nextTick } from 'vue'
import { useGetContent, useGetContentOptions } from '#root/components/composables/getContent.js'

import HtmlContent from '#root/components/utils/HtmlContent.vue'
import InfoIcon from '#root/assets/images/icons/info-icon.svg'

const emit = defineEmits(['resize-elements'])

const props = defineProps({
  content: { type: Object, default: () => ({}) },
  resizeElements: { type: Function, default: () => ({}) }
})

const cookieSet = ref(true)

onMounted(() => {
  cookieSet.value = document.cookie.includes('termsAccepted')
})

const cookieContent = computed(() => {
  return {
    cookie: useGetContent(props.content, 'cookie_content.value', null),
    alert: useGetContent(props.content, 'alert_content.value', null),
    hide: useGetContentOptions('hide_cookies.value[0]', null)
  }
})

const gtm = computed(() => {
  return {
    cookieLink: useGetContentOptions('cookies_policy.value', 'cookies policy'),
    cookieBtn: useGetContentOptions('cookies_cta.value', 'cookies ok')
  }
})

const handleClick = () => {
  cookieSet.value = true
  document.cookie = 'termsAccepted=1'

  nextTick(() => {
    emit('resize-elements')
  })
}

const cookieChecks = () => {
  // check in the browser if the cookies are enable.
  const cookieCheck = window.navigator.cookieEnabled
  return cookieCheck
}
</script>

<style lang="scss">
.cookie-container {
  position: absolute;
  width: 100%;
  z-index: 10;
  background-color: $white;
  min-height: 60px;
  top: 0;
  transition: all 0.3s ease-in-out;

  &.hide {
    height: 0;
    transition: all 0.3s ease-in-out;
    min-height: 0;
    overflow: hidden;
    padding: 0;
  }

  &.alert {
    background-color: $yellow;
    min-height: auto;

    a {
      color: $core-font;
      text-decoration: underline;
      font-size: $legal-text;
      font-weight: $w-subheading;
    }

    .cookie-notice {
      padding: $spacing-2 $spacing-6;
    }

    .cookie-content {
      padding-right: 0;
    }

    .cookie-close {
      display: none;
    }
  }
}

.cookie {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: $g-desktop-large-breakpoint-min-width;
  margin: auto;
  width: 100%;
}

.cookie-notice {
  color: $core-font;
  position: relative;
  z-index: 3;
  @include flex-parent(row, nowrap, flex-start, center);
  max-width: $g-desktop-large-breakpoint-min-width;
  margin: auto;
  width: 100%;

  .info-icon {
    margin-right: $spacing-2;
    width: 24px;
    height: 24px;
    flex-shrink: 0;
  }

  .cookie-content {
    padding-right: $spacing-6;
  }

  p {
    margin: 0;
    font-weight: $w-body;
    font-size: $legal-text;
    line-height: 18px;
  }

  a {
    color: $core-font;
    text-decoration: underline;
    font-size: $legal-text;
    font-weight: $w-body;
  }
}

.cookie-close {
  cursor: pointer;
  align-self: flex-start;
  border: 2px solid $core-font;
  border-radius: $spacing-1;
  padding: 5px 13px;
  text-decoration: none;
  font-weight: $w-subheading;
}
</style>
