<!--
  * Component: Footer Nav
  * Description: Menus for Footer component
  * Props:
      content, nofollow
  * Usage:
      <FooterNav :content="value" :nofollow="nofollow" />
-->

<template>
  <div class="footer-nav-container pb-6">
    <div v-for="(navItem, index) in content" :key="index" class="footer-nav-item">
      <div class="footer-nav-item-title pb-6" v-html="navItem.elements.nav_title.value" />
      <div
        v-for="(nav, subindex) in navItem.elements.nav_items.linkedItems"
        :key="subindex"
        class="footer-nav"
      >
        <a
          :href="useGetUrl(nav).url"
          class="footer-nav-link pb-4"
          :data-gtm="gtm.siteLinks"
          :rel="nofollow"
          :target="useGetUrl(nav).target"
        >
          {{ useGetUrl(nav).title }}
        </a>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useGetContentOptions, useGetUrl } from '#root/components/composables/getContent.js'

const props = defineProps({
  content: { type: Object, required: true, default: () => ({}) },
  nofollow: { type: String, required: false, default: '' }
})

const gtm = computed(() => {
  return {
    siteLinks: useGetContentOptions('site_links.value', '')
  }
})
</script>

<style lang="scss">
.footer-nav-container {
  @include flex-parent(column, wrap, space-between, flex-start);
  border-bottom: 1px solid $gs-400;

  @include media-query('phablet') {
    flex-direction: row;
  }
}

.footer-nav-item-title h5 {
  color: $footer-header;
}

.footer-nav-item {
  flex: 0 0 100%;

  @include media-query('phablet') {
    flex: 0 0 49%;
  }

  @include media-query('desktop') {
    flex: 0 0 24%;
  }
}

.footer-nav {
  width: 100%;

  @include media-query('tablet') {
    width: auto;
  }
}

.footer-nav-link {
  display: block;
  color: $footer-font;
  font-size: $body-small;
  line-height: 24px;
  font-weight: $w-heading;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
</style>
