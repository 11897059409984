import { createController } from '#root/utils/helpers/helperApi'

const request = createController('/leads')

export const postTransactions = ({ data }) =>
  request({
    url: '/transactions',
    method: 'post',
    data
  })

export const postRefreshTransactions = (transactionId) => {
  return request({
    url: '/refresh-transactions',
    method: 'post',
    data: {
      transactionId
    }
  })
}

export const quoteRequest = ({ data }) =>
  request({
    url: '/quote-request',
    method: 'post',
    data
  })

export const postInfoPack = ({ data }) =>
  request({
    url: '/info-pack',
    method: 'post',
    data
  })

export const postCallMeLater = ({ data }) =>
  request({
    url: '/call-me-later',
    method: 'post',
    data
  })

export const postCallMeNow = ({ data }) =>
  request({
    url: '/call-me-now',
    method: 'post',
    data
  })

export const postWillKit = ({ data }) =>
  request({
    url: '/will-kit',
    method: 'post',
    data
  })
