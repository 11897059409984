import { computed } from 'vue'
import { defineStore } from 'pinia'
import { useSetter } from '#root/utils/helpers'
import { Customer } from '#root/utils/models'

export const useCustomerStore = defineStore('customer', () => {
  const [customer, setCustomer] = useSetter(new Customer())
  const [productCode, setProductCode] = useSetter(null)
  const [leadbyteQueueId, setLeadbyteQueueId] = useSetter(null)

  const getCustomer = computed(() => {
    const { customer } = useCustomerStore()

    return new Customer(
      customer?.customerId,
      customer?.firstName,
      customer?.lastName,
      customer?.dob,
      customer?.email,
      customer?.postCode,
      customer?.phone,
      customer?.province,
      customer?.provinceId,
      customer?.ageCheck,
      customer?.leadbyteQueueId
    )
  })

  return {
    customer,
    productCode,
    getCustomer,
    setCustomer,
    setProductCode,
    leadbyteQueueId,
    setLeadbyteQueueId
  }
})
