import { createApp } from './app'
import { helperRender } from '#root/utils/helpers'
import { useHeadPatch } from '#root/components/composables/head'
import { useTrackingStore } from '#root/stores'
export { onRenderClient }

let app = null

const onRenderClient = async (pageContext) => {
  if (!app) {
    const instance = createApp(pageContext)
    app = instance.app
    instance.store.state.value = pageContext.initialStoreState
    app.mount('#app')
  } else {
    const exitWarning = document.querySelector('meta[name="exit-warning"][content]').content === 'enable'
    if (exitWarning && (pageContext.isBackwardNavigation || pageContext.isBackwardNavigation === null)) {
      app.cancelPageChange(pageContext)
    } else {
      const trackingStore = useTrackingStore()
      trackingStore.setInContentCtaCountersElms({
        quote: [],
        learnMore: []
      })
      await helperRender.renderFetchPage({ pageContext })
      useHeadPatch(pageContext)
      app.changePage(pageContext)
    }
  }
}
