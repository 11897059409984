<script setup>
import { defineAsyncComponent } from 'vue'
import { usePageContext } from '#root/renderer/usePageContext'

const pageContext = usePageContext()
const brand = pageContext.env.BRAND || 'bsia'

const props = defineProps({
  name: { type: String, required: true }
})

const dynamicComponent = defineAsyncComponent(() =>
  import(`../../assets/images/icons/${brand}/${props.name}.svg`)
)
</script>

<template>
  <component :is="dynamicComponent" />
</template>
