import { defineStore } from 'pinia'
import { useSetter } from '#root/utils/helpers'

export const useBuyOnlineStore = defineStore('buyOnline', () => {
  // store state
  const [products, setProducts] = useSetter([])
  const [productCode, setProductCode] = useSetter(null)
  const [userInfo, setUserInfo] = useSetter({
    firstName: '',
    lastName: '',
    dob: {
      year: '',
      month: null,
      day: null
    },
    email: '',
    gender: null,
    smokingStatus: null,
    phone: ''
  })
  const [preferences, setPreferences] = useSetter(null)
  const [customerDetails, setCustomerDetails] = useSetter({})
  const [partnerDetails, setPartnerDetails] = useSetter({
    firstName: '',
    lastName: '',
    dob: {
      year: '',
      month: null,
      day: null
    },
    gender: null,
    smokingStatus: null
  })
  const [partnerGuid, setPartnerGuid] = useSetter(null)
  const [customerAddress, setCustomerAddress] = useSetter({})
  const [customerGuid, setCustomerGuid] = useSetter(null)
  const [paymentDetails, setPaymentDetails] = useSetter({
    accountName: null,
    sortCode: {
      a: '',
      b: '',
      c: ''
    },
    fullSort: '',
    accountNumber: '',
    branch: null,
    paymentStart: null,
    confirm: false
  })
  const [estimate, setEstimate] = useSetter({})
  const [topUp, setTopUp] = useSetter({})
  const [partnerTopup, setPartnerTopUp] = useSetter({})
  const [quote, setQuote] = useSetter({})
  const [confirmedEstimate, setConfirm] = useSetter(false)
  const [noticeBar, setNoticeBar] = useSetter(null)
  const [progressStep, setProgressStep] = useSetter(1)
  const [promo, setPromo] = useSetter({
    affiliate_offer: null
  })
  const journeyComplete = useSetter(0)
  const [showPromo, setShowPromo] = useSetter(true)
  const [showQuotePromo, setShowQuotePromo] = useSetter(true)
  const [showRequestCallCTA, setShowRequestCallCTA] = useSetter(true)

  // store getters
  const product = () => {
    return products && products.length ? products[0] : 'BR-O5LI-14'
  }
  const accountHolder = () => {
    return (userInfo.gender === 1 ? 'Mr.' : 'Mrs.') + ' ' + userInfo.firstName + ' ' + userInfo.lastName
  }

  const setJourneyComplete = () => {
    journeyComplete.value = 1
  }

  const setQuoteId = (id) => {
    quote.id.value = id
  }
  const setQuoteComplete = (complete) => {
    quoteComplete.value = complete
  }

  // const getProducts = () => {
  //   if (!products.length) {
  //     return getProductSearch()
  //       .then((res) => {
  //         // context.commit('setProducts', res)
  //         setProducts(res)
  //       })
  //       .catch((error) => {
  //         // console.log(error)
  //       })
  //   }
  // }
  // const removePartner = (customerId) => {

  //   const data = {
  //     customerId
  //   }
  //   return deleteCustomer({ data }, { root: true })
  //     .then((res) => {
  //       setPartnerGuid(null)
  //       // commit('setPartnerGuid', null)
  //       setPartnerDetails({
  //         firstName: '',
  //         lastName: '',
  //         dob: {
  //           year: '',
  //           month: null,
  //           day: null
  //         },
  //         gender: null,
  //         smokingStatus: null
  //       })
  //     })
  //     .catch((error) => {
  //       console.log(error)
  //     })
  // }

  return {
    products,
    productCode,
    userInfo,
    preferences,
    customerDetails,
    partnerDetails,
    partnerGuid,
    customerAddress,
    customerGuid,
    paymentDetails,
    estimate,
    topUp,
    partnerTopup,
    quote,
    confirmedEstimate,
    noticeBar,
    progressStep,
    promo,
    journeyComplete,
    showPromo,
    showQuotePromo,
    showRequestCallCTA,
    product,
    accountHolder,
    setProducts,
    setProductCode,
    setShowPromo,
    setShowQuotePromo,
    setCustomerGuid,
    setCustomerDetails,
    setUserInfo,
    setPartnerDetails,
    setPartnerGuid,
    setCustomerAddress,
    setEstimate,
    setTopUp,
    setPartnerTopUp,
    setJourneyComplete,
    setQuote,
    setQuoteId,
    setQuoteComplete,
    setPaymentDetails,
    setPreferences,
    setConfirm,
    setNoticeBar,
    setProgressStep,
    setPromo,
    setShowRequestCallCTA
    // getProducts,
    // removePartner
  }
})
