<!--
  * Component: Footer Contact
  * Description: Phone and opening times for footer component
  * Props:
      phoneNum, closingTime
  * Usage:
      <footer-social />
-->

<template>
  <div class="footer-contact-container" :class="type === 'focused' ? 'm-focused' : 'm-standard'">
    <div class="footer-contact-phone">
      <a :href="'tel:' + dynamicPhoneNumber" aria-label="Call">
        <PhoneIcon class="info" />
        {{ dynamicPhoneNumber }}
      </a>
      <HtmlContent
        v-if="content.openingTimes"
        class="footer-contact-copy mt-2"
        :content="content.openingTimes"
      />
    </div>
    <div
      v-if="type === 'focused' && (content.footerImageCopy || content.footerLogo)"
      class="footer-image-container mt-10 xl:mt-0"
    >
      <img
        v-if="content.footerLogo"
        :src="helperImage.optimise(content.footerLogo)"
        :alt="content.footerLogo?.description || 'Footer Logo'"
        fetchpriority="low"
        decoding="async"
        loading="lazy"
      />
      <div class="footer-trust-container">
        <div v-if="content.footerImageCopy" class="footer-image-copy mb-4 md:mb-0 md:mr-4">
          {{ content.footerImageCopy }}
        </div>
        <img
          v-if="content.footerImage"
          :src="helperImage.optimise(content.footerImage)"
          :alt="content.footerImage?.description || 'Trust Image'"
          width="205"
          height="23"
          fetchpriority="low"
          decoding="async"
          loading="lazy"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { storeToRefs } from 'pinia'
import { useGlobalStore } from '#root/stores'
import { helperImage } from '#root/utils/helpers'
import PhoneIcon from '#root/assets/images/icons/phone-cta-icon.svg'
import HtmlContent from '#root/components/utils/HtmlContent.vue'

const props = defineProps({
  content: { type: Object, required: true, default: () => ({}) },
  type: { type: String, required: true, default: 'standard' }
})

const globalStore = useGlobalStore()
const { dynamicPhoneNumber } = storeToRefs(globalStore)
</script>

<style lang="scss">
.footer-contact-container.m-standard {
  @include flex-parent(column, nowrap, space-between, flex-start);
  flex: 1;

  @include media-query('tablet') {
    flex-direction: row;
    align-items: center;
  }
}

.footer-contact-phone {
  text-align: center;
  width: 100%;

  a {
    text-decoration: none;
    color: inherit;
    font-size: 2.8rem;
    line-height: 36px;
    font-weight: $w-heading;
    color: $footer-header;

    @include media-query('desktop') {
      pointer-events: none;
    }
  }

  @include media-query('tablet') {
    text-align: left;
  }

  @if mixin-exists('footer-phone-standout') {
    @include footer-phone-standout();
  }
}

.footer-contact-copy p {
  font-size: $body-small;
  line-height: 28px;
  font-weight: $w-body;
  color: $footer-font;
  max-width: 420px;
  margin-right: auto;
  margin-left: auto;

  @include media-query('tablet') {
    margin-right: 0;
    margin-left: 0;
  }
}

// Focuesed footer
.footer-contact-container.m-focused {
  @include flex-parent(column, nowrap, space-between, flex-start);

  @include media-query('desktop') {
    flex-direction: row;
    align-items: center;
  }
}

.footer-image-container {
  display: flex;
  flex-direction: column;
  gap: $spacing-4;

  img {
    width: fit-content;
  }

  @include media-query('desktop') {
    align-items: flex-end;
    gap: 8px;
  }
}

.footer-trust-container {
  @include flex-parent(column, nowrap, center, flex-start);
  flex: 1 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 48px;

  @include media-query('phablet') {
    flex-direction: row;
    align-items: center;
  }

  @include media-query('desktop') {
    flex: none;
    width: auto;
  }
}

.footer-image-copy {
  font-family: $secondary-font;
  font-size: $h4-d;
  line-height: 48px;

  @include media-query('phablet') {
    font-size: 3.2rem;
  }
}

.footer-contact-container.m-focused {
  margin-bottom: $spacing-3;

  .footer-contact-phone {
    text-align: left;

    @include media-query('desktop') {
      flex: 1 0 50%;
    }
  }

  .footer-contact-copy p {
    margin-right: 0;
    margin-left: 0;
  }
}
</style>
