import { defineStore } from 'pinia'
import { useSetter } from '#root/utils/helpers'

export const useUnsubscribeStore = defineStore('unsubscribe', () => {
  // store state
  const [unsubscribe, setUnsubscribe] = useSetter(null)

  // store getters

  // store actions

  return {
    unsubscribe,
    setUnsubscribe
  }
})
