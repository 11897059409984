import { useGtm } from '@gtm-support/vue-gtm'
import { useGlobalStore, useTrackingStore } from '#root/stores'

export const getDataLayer = () => {
  if (!Array.isArray(window.dataLayer)) {
    const dataLayer = []
    window.dataLayer = dataLayer
    return dataLayer
  } else {
    return window.dataLayer
  }
}

export const mapDataLayerObj = ({ env }) => {
  const globalStore = useGlobalStore()
  const trackingStore = useTrackingStore()

  return {
    visitorId: globalStore.visitorId,
    visitId: globalStore.transactionId,
    webRefCode: trackingStore.webRefCode || globalStore.config.refCode || '',
    onlineChannelGroup: globalStore.config.mediaChannelName,
    mediaChannelId: globalStore.config.mediaChannelId,
    productCode: globalStore.config.defaultProductCode,
    leadType: 'browsing',
    googleAnalyticsId: env.GOOGLE_ANALYTICS_ID,
    cjEvent: globalStore.queryParams?.clickref || trackingStore.clickRef || '',
    userInfo: { send: false },
    ppclid: globalStore.queryParams?.ppclid || '',
    ppcrid: globalStore.queryParams?.ppcrid || '',
    ppsubid: globalStore.queryParams?.ppsubid || ''
  }
}

export const initGTM = (context) => {
  const gtm = useGtm()
  const trackingStore = useTrackingStore()
  gtm.enable(true)
  trackingStore.setGtmActive(true)
  checkOneTrustAcceptance(context)
}

export const checkOneTrustAcceptance = (context) => {
  const trackingStore = useTrackingStore()
  if (context.env.ONETRUST != '') {
    getDataLayer().some(
      (o) => o.event === 'OneTrustGroupsUpdated' && o.OnetrustActiveGroups.includes('C0002')
    )
      ? trackingStore.setOnetrustAccepted(true)
      : setTimeout(() => checkOneTrustAcceptance(context), 100)
  } else {
    trackingStore.setOnetrustAccepted(true)
  }
}

export const checkOneTrustLoadState = (context) => {
  if (context.env.ONETRUST != '') {
    getDataLayer().some((o) => o.event === 'OneTrustLoaded')
      ? initGTM(context)
      : setTimeout(() => checkOneTrustLoadState(context), 100)
  } else {
    initGTM(context)
  }
}
