import { createController } from '#root/utils/helpers/helperApi'

const request = createController('', '/tracking', {
  headers: {} // by default don't add additional request headers
})

export const validate = () => request({ url: '/validate' })

export const activate = (
  campaignKey,
  visitorId,
  location,
  userAgent,
  visitorIP,
  ntsqa,
  rc,
  ppclid,
  ppcrid,
  ppsubid
) => {
  return request({
    url: '/activate',
    params: {
      campaignKey,
      visitorId,
      location,
      userAgent,
      visitorIP,
      ntsqa,
      rc,
      ppclid,
      ppcrid,
      ppsubid
    }
  })
}

export const track = (campaignKey, goalIdentifier, location, userAgent, ntsqa, rc, ppclid, ppcrid, ppsubid) =>
  request({
    url: '/track',
    params: {
      campaignKey,
      goalIdentifier,
      location,
      userAgent,
      ntsqa,
      rc,
      ppclid,
      ppcrid,
      ppsubid
    }
  })

export const push = (vwoDimensions) =>
  request({
    method: 'post',
    url: '/push',
    headers: {
      // set request body type as JSON
      'Content-Type': 'application/json; charset=utf-8'
    },
    data: { ...vwoDimensions }
  })
