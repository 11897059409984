import { helperScroll } from '#root/utils/helpers'
import { useGlobalStore } from '#root/stores'

export const toggle = (popout) => {
  const globalStore = useGlobalStore()
  const result = globalStore.togglePopout(popout)
  const popoutElement = document.querySelector('.' + popout)
  const popoutButton = document.querySelector('.m-' + popout)
  if (result === true) {
    if (popoutElement) popoutElement.classList.add('m-active')
    if (popoutButton) popoutButton.classList.add('m-opened')
    helperScroll.scrollToElement('.' + popout)
  } else if (result === false) {
    if (popoutElement) popoutElement.classList.remove('m-active')
    if (popoutButton) popoutButton.classList.remove('m-opened')
    helperScroll.scrollToElement('.m-' + popout, 200)
  }
}
