import { ref, computed } from 'vue'
import { defineStore } from 'pinia'
import { useGtm } from '@gtm-support/vue-gtm'
import { helperVwo, helperTracking } from '#root/utils/helpers'
import { useGlobalStore, usePagesStore, useTrackingStore } from '#root/stores'
import { tracking as trackingAPI } from '#root/api'

// Import novus-server helperContent only when SSR
let helperVWOTracking = null

const initServer = async () => {
  if (import.meta.env.SSR) {
    const modules = await import('#server')

    if (modules) {
      helperVWOTracking = modules.helpers.tracking
      await helperVWOTracking.init()
    }
  }
}

export const useVWOStore = defineStore('vwo', () => {
  const gtm = useGtm()
  const globalStore = useGlobalStore()
  const vwoActivatedCampaigns = ref([])
  const vwoSettingsFileCampaigns = ref([])
  const vwoVisitorDimensions = ref({})
  const vwoLog = ref([])

  const getPageKey = (prefix = '') => {
    const sorted = [...vwoActivatedCampaigns.value].sort((a, b) => (a.campaignKey > b.campaignKey ? 1 : -1))
    const vwoHashParts = sorted.map((c) => `${c.campaignKey}+${c.assignedVariantName}`)

    return `key:${prefix}:::${vwoHashParts.join('|')}`
  }
  // for now this is the same as visitorId
  const vwoVisitorId = computed(() => {
    const { visitorId } = useGlobalStore()

    return visitorId
  })

  const activeHomepageCampaign = computed(() => {
    return () =>
      vwoActivatedCampaigns.value.find((c) => {
        return c.assignedVariantName === 'Variation-1' && c.home_page.value[0]?.codename === 'yes'
      }) || {}
  })

  const activePageCampaign = computed(() => {
    return (route) => {
      return (
        vwoActivatedCampaigns.value.find(
          (c) =>
            c.assignedVariantName === 'Variation-1' &&
            c.pages.linkedItems.find((p) => p?.elements?.url?.value === route)
        ) || {}
      )
    }
  })

  const setVwoLog = (log) => {
    vwoLog.value.push(log)
  }

  const setVwoActivatedCampaigns = (vwoActiveCampaign) => {
    const activeCampaign = vwoActivatedCampaigns.value.find(
      (c) => c.campaign_key.value === vwoActiveCampaign.campaign_key.value
    )
    if (activeCampaign) {
      return
    }
    vwoActivatedCampaigns.value.push(vwoActiveCampaign)
  }

  const setVwoSettingsFileCampaigns = (campaigns) => {
    vwoSettingsFileCampaigns.value = campaigns.length ? campaigns : []
  }

  const setVwoVisitorDimensions = (visitorDimensions) => {
    vwoVisitorDimensions.value = { ...vwoVisitorDimensions.value, ...visitorDimensions }
  }

  const initVwoFullStackSettings = async ({ route, req }) => {
    const storeGlobal = useGlobalStore()
    // Init and get vwo sdk instance
    if (!helperVWOTracking) {
      await initServer()
    }

    setVwoVisitorDimensions({
      ...helperVwo.visitorDimensionMap(req, route),
      VisitorID: vwoVisitorId.value,
      Country: storeGlobal.visitorLocation.countryCode,
      WebRefCode: storeGlobal.config.refCode || '',
      OnlineChannelGroup: storeGlobal.config.mediaChannelName,
      TransactionID: storeGlobal.transactionId || '',
      IPAddress: storeGlobal.visitorIP || ''
    })
  }

  const vwoActivateCampaign = async (pageContext) => {
    const { queryParams, visitorLocation, visitorIP } = useGlobalStore()
    const pageStore = usePagesStore()
    const isServer = !!import.meta.env.SSR
    const isNtsQa = isServer ? pageContext.request?.query?.ntsqa : queryParams?.ntsqa || false
    const { rc, ppclid, ppcrid, ppsubid } = queryParams
    const route = pageContext.urlPathname
    let validationRequest

    if (isServer) {
      validationRequest = await helperVWOTracking.isValidRequest(pageContext.request)
    } else {
      validationRequest = await trackingAPI.validate()
    }
    if (!validationRequest.valid) {
      setVwoLog(validationRequest)
      return false
    } else {
      setVwoLog(validationRequest)
      if (validationRequest.campaigns.length) {
        setVwoSettingsFileCampaigns(validationRequest.campaigns)
      }
    }

    const activeKenticoCampaigns = pageStore.campaigns
    let campaigns = []

    if (route === '/') {
      const campaignAllPage = helperVwo.findAllPages(activeKenticoCampaigns, vwoSettingsFileCampaigns.value)
      if (campaignAllPage) {
        campaigns.push(...campaignAllPage)
      }
      const campaignHome = helperVwo.findCampaignIndex(activeKenticoCampaigns, vwoSettingsFileCampaigns.value)
      if (campaignHome) {
        campaigns.push(campaignHome)
      }
    } else {
      const campaignAllPage = helperVwo.findAllPages(activeKenticoCampaigns, vwoSettingsFileCampaigns.value)
      if (campaignAllPage) {
        campaigns.push(...campaignAllPage)
      }
      const campaignPage = helperVwo.findCampaignPage(
        activeKenticoCampaigns,
        vwoSettingsFileCampaigns.value,
        route
      )
      if (campaignPage) {
        campaigns.push(campaignPage)
      }
    }

    if (!campaigns.length) {
      setVwoLog('no campaigns found on route')
      return false
    }

    for (const c of campaigns) {
      const activeCampaign = vwoActivatedCampaigns.value.find(
        (a) => a.campaign_key.value === c.campaign_key.value
      )
      if (!activeCampaign?.assignedVariantName) {
        const location = isNtsQa ? 'ServerSide' : visitorLocation.countryCode
        let variationName

        if (isServer) {
          const userAgent = pageContext.request.headers['user-agent']
          variationName = await helperVWOTracking.activate(
            c.campaign_key.value,
            vwoVisitorId.value,
            location,
            userAgent,
            visitorIP,
            isNtsQa,
            rc,
            ppclid,
            ppcrid,
            ppsubid
          )
        } else {
          const userAgent = navigator.userAgent
          variationName = await trackingAPI.activate(
            c.campaign_key.value,
            vwoVisitorId.value,
            location,
            userAgent,
            visitorIP,
            isNtsQa,
            rc,
            ppclid,
            ppcrid,
            ppsubid
          )
        }

        if (variationName) {
          c.assignedVariantName = variationName
          c.campaignKey = c.campaign_key.value
          c.landingPage = route
          c.conversion = false
          setVwoActivatedCampaigns(c)
          setVwoLog({ message: 'Activated', campaign: c })
          if (!import.meta.env.SSR || pageContext.isClientSideNavigation) {
            vwoTransaction(pageContext)
            vwoPush(pageContext)
            vwoGTMPush(c.campaignKey, pageContext)
          }
        }
      } else {
        vwoGTMPush(c.campaignKey, pageContext)
      }
    }
  }

  const vwoTrack = async ({ campaignKey, goalIdentifier }, pageContext) => {
    if (!vwoActivatedCampaigns.value.length) {
      return false
    }
    const { queryParams, visitorLocation } = useGlobalStore()
    const { rc, ppclid, ppcrid, ppsubid } = queryParams
    const isNtsQa = queryParams?.ntsqa || false
    const location = isNtsQa ? 'ServerSide' : visitorLocation.countryCode
    const isServer = !!import.meta.env.SSR
    let conversion
    if (isServer) {
      const userAgent = pageContext.request.headers['user-agent']
      conversion = await helperVWOTracking.track(
        campaignKey,
        vwoVisitorId.value,
        goalIdentifier,
        location,
        userAgent,
        isNtsQa,
        rc,
        ppclid,
        ppcrid,
        ppsubid
      )
    } else {
      const userAgent = navigator.userAgent
      conversion = await trackingAPI.track(
        campaignKey,
        goalIdentifier,
        location,
        userAgent,
        isNtsQa,
        rc,
        ppclid,
        ppcrid,
        ppsubid
      )
    }

    if (conversion) {
      const convertedCampaign = vwoActivatedCampaigns.value.find((c) => c.campaignKey === campaignKey)
      convertedCampaign.conversion = true
      setVwoLog({ message: 'Tracked', campaign: campaignKey })
      vwoTransaction(pageContext)
    }
  }

  const vwoPush = async () => {
    if (!vwoActivatedCampaigns.value.length) {
      return false
    }
    const isServer = !!import.meta.env.SSR
    if (isServer) {
      await helperVWOTracking.push(vwoVisitorDimensions.value, vwoVisitorId.value)
    } else {
      await trackingAPI.push(vwoVisitorDimensions.value)
    }
    setVwoLog({ message: 'Pushed', visitorDimensions: vwoVisitorDimensions.value })
  }

  const vwoTransaction = async (pageContext) => {
    if (!vwoActivatedCampaigns.value.length) {
      return false
    }
    const { setTransactionVwo, callCenterOpen } = useGlobalStore()
    const landingPage = vwoVisitorDimensions.value.LandingPageFull
    const data = {
      ...vwoVisitorDimensions.value,
      visitorId: vwoVisitorId.value,
      callCenterOpen: callCenterOpen,
      campaigns: vwoActivatedCampaigns.value.map((c) => {
        return {
          campaignName: c.campaign_name.value || c.campaign_key.value,
          campaignKey: c.campaign_key.value,
          assignedVariantName: c.assignedVariantName,
          goalIdentifier: c.goal_identifier.value,
          conversion: c.conversion
        }
      })
    }
    setVwoLog({ message: 'Transaction', data: data })
    setTransactionVwo(data, landingPage)
  }

  const vwoGTMPush = (campaignKey, pageContext) => {
    const { onetrustAccepted } = useTrackingStore()
    if (import.meta.env.SSR || globalStore.isBot || !vwoActivatedCampaigns.value.length) {
      return
    }
    vwoActivatedCampaigns.value.forEach((c) => {
      if (pageContext.env.ONETRUST) {
        if (c.campaignKey === campaignKey && onetrustAccepted) {
          const campaign = {}
          campaign[c.campaign_key.value] = c.assignedVariantName
          setTimeout(() => {
            helperTracking.getDataLayer().push(campaign)
            gtm.trackEvent({
              event: 'VWO'
            })
          }, 2000)
        }
      } else {
        if (c.campaignKey === campaignKey) {
          const campaign = {}
          campaign[c.campaign_key.value] = c.assignedVariantName
          setTimeout(() => {
            helperTracking.getDataLayer().push(campaign)
            gtm.trackEvent({
              event: 'VWO'
            })
          }, 2000)
        }
      }
      setVwoLog({ message: 'GTMPushed', campaign: c.campaign_key.value })
    })
  }

  const vwoTrackLead = (pageContext) => {
    if (!vwoActivatedCampaigns.value.length) {
      return
    }
    const trackLeadCampaigns = helperVwo.isTrackLeadGoal(vwoActivatedCampaigns)
    trackLeadCampaigns.forEach((c) => {
      vwoTrack(
        {
          campaignKey: c.campaign_key.value,
          goalIdentifier: c.goal_identifier.value
        },
        pageContext
      )
      setVwoLog({ message: 'TrackLead', campaign: c.campaign_key.value })
    })
  }

  const vwoTrackRoute = async (to, pageContext) => {
    const trackRouteCampaigns = helperVwo.isRouteGoal(vwoActivatedCampaigns.value, to)
    trackRouteCampaigns.forEach((c) => {
      if (!c.conversion) {
        vwoTrack(
          {
            campaignKey: c.campaign_key.value,
            goalIdentifier: c.goal_identifier.value
          },
          pageContext
        )
        setVwoLog({ message: 'TrackRoute', campaign: c.campaign_key.value })
      }
    })
  }

  const vwoRegisterEventListeners = (pageContext) => {
    if (!vwoActivatedCampaigns.value.length) {
      return
    }
    vwoActivatedCampaigns.value.forEach((c) => {
      if (c.conversion_event.value && c.conversion_event_element) {
        const elm = document.querySelector(c.conversion_event_element.value)
        if (!elm) {
          return
        }
        document.addEventListener('vwoEvent', (e) => {
          e.detail.convert()
        })
        elm.addEventListener(c.conversion_event.value, function () {
          this.dispatchEvent(
            new CustomEvent('vwoEvent', {
              bubbles: true,
              detail: {
                convert: () => {
                  vwoTrack(
                    {
                      campaignKey: c.campaign_key.value,
                      goalIdentifier: c.goal_identifier.value
                    },
                    pageContext
                  )
                  setVwoLog({ message: 'TrackEvent', campaign: c.campaign_key.value })
                }
              }
            })
          )
        })
      }
    })
  }

  return {
    getPageKey,
    initVwoFullStackSettings,
    vwoActivateCampaign,
    vwoTrack,
    vwoPush,
    vwoGTMPush,
    vwoTrackLead,
    vwoRegisterEventListeners,
    setVwoVisitorDimensions,
    activePageCampaign,
    activeHomepageCampaign,
    vwoVisitorId,
    vwoActivatedCampaigns,
    vwoSettingsFileCampaigns,
    vwoVisitorDimensions,
    vwoLog,
    vwoTrackRoute,
    vwoTransaction
  }
})
