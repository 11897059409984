import { ref } from 'vue'
import { defineStore } from 'pinia'
import { useSetter } from '#root/utils/helpers'

export const useArticleStore = defineStore('article', () => {
  // store state
  const latest = ref({})
  const pages = ref({})
  const [totalCount, setTotalCount] = useSetter(null)
  const baseItems = ref([])

  // store getters

  // store actions
  const setLatest = (content) => {
    latest.value[content.articleType] = content.data
  }
  const setArticlePage = (content) => {
    pages.value[content.page] = content.data
  }
  const setArticleBase = (content) => {
    baseItems.value = content
  }

  return {
    latest,
    pages,
    totalCount,
    baseItems,
    setLatest,
    setArticlePage,
    setTotalCount,
    setArticleBase
  }
})
