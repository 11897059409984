export const callCenterName = 'AUCallCenter'
// https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
export const callCenterTimezone = 'Australia/Sydney'

export const formatPhoneNumber = (phoneNumber, fallbackPhoneNumber) => {
  const formattedNumber = phoneNumber ? phoneNumber.replace(' ', '') : fallbackPhoneNumber || ''
  if (!formattedNumber) return ''

  // Remove leading 0 if it exists
  let strippedNumber = formattedNumber.replace(/^0/, '')

  return strippedNumber.substr(0, 4) + ' ' + strippedNumber.substr(4, 3) + ' ' + strippedNumber.substr(7, 3)
}

// we are validating only min and max length of digits - refer to TBCR-604
export const phonePattern = '^\\d{8,11}$'

export const showAppointmentTimezone = true
